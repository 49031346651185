<template>
  <div class="tag">
    <p class="px-2 p-1">
      <slot/>
    </p>
  </div>
</template>

<script>
export default {
  name: "PTag"
}
</script>

<style scoped lang="scss">
@import "../styles/colors";

$colors: ("green": $soft--green-light, "red":$soft--red, "blue":$soft--blue, "white": $neutral--light, "purple":$soft--purple);

@each $name, $color in $colors {
  .#{$name} {
    p,
    p:after {
      color: $color;
    }
  }
}

p {
  font-size: .8rem;
  text-transform: uppercase;
  border: 1px solid;
  display: inline-block;
  color: red;
}
</style>