<template>
  <div class="container" :class="color" :style="`--title:'${title}'`">
    <div class="row">
      <slot name="banner">
      </slot>
    </div>
    <div class="row mt-4 align-items-center mx-4" :class="{'flex-row-reverse':right}">
      <div :class="{'col-md-6':sideImage}" class="col-12">
        <div class="row">
          <div v-if="sideImage" class="col-12 col-md-6 mt-3 mt-md-0 d-flex d-md-none justify-content-center" :class="`glow-img-${color}`">
            <kinesis-container>
              <kinesis-element :strength="15">
                <img :src="sideImage" alt="" class="side-image w-100 h-md-50">
              </kinesis-element>
            </kinesis-container>
          </div>
          <h1 :name="title" :id="`anchor-${title}`" class="section__delimiter text-md-start text-center" :class="{'text-md-center':center}"
              v-if="title"
              :data-aos="right ? 'fade-left':'fade-right'">{{ title }} <i class="fas" :class="`fa-${icon}`"></i></h1>
          <p-tag v-if="comingSoon" data-aos="fade-left" class="text-md-start text-center" :class="{'text-md-center':center}">coming soon</p-tag>
          <slot name="title">
          </slot>
        </div>
        <div class="row align-items-end">
          <div class="col text-md-start text-center" data-aos="fade-up">
            <slot name="content">
            </slot>
          </div>
        </div>
      </div>
      <div v-if="sideImage" class="col-12 col-md-6 mt-3 mt-md-0 d-none d-md-flex d-flex justify-content-center" :class="`glow-img-${color}`">
        <kinesis-container>
          <kinesis-element :strength="15">
            <img :src="sideImage" alt="" class="side-image side-image__desk">
          </kinesis-element>
        </kinesis-container>
      </div>
    </div>
    <div class="row mt-3 text-center mt-5">
      <slot name="additional">
      </slot>
    </div>
  </div>
</template>

<script>
import PTag from "./PTag";

export default {
  name: "PSection",
  props: {
    title: {
      type: String
    },
    sideImage: {
      type: String
    },
    right: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    color: {
      type: String,
      default: "white"
    },
    comingSoon: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "p-tag": PTag
  }
}
</script>

<style scoped lang="scss">
@import "../styles/colors";

$colors: ("green": $soft--green-light, "red":$soft--red, "blue":$soft--blue, "white": $neutral--light, "purple":$soft--purple);

@each $name, $color in $colors {
  .#{$name} {
    h1,
    h1:after {
      color: $color;
    }
  }
}

h1[id^='anchor-'] {
  position: relative;
  z-index: 2;
  font-size: 4rem;

  &:after {
    content: var(--title);
    position: absolute;
    font-size: 150%;
    z-index: -1;
    opacity: .2;
    left: 0;
    pointer-events: none;
  }
}

img {
  max-width: 100%;
  //max-height: 40vh;
}

[class*='glow-img-']  {
  position: relative;
  &:before {
    content:"";
    height: 80px;
    width: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    border-radius: 50%;
    opacity: .8;
    animation-name: glowLoop;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}

.glow-img-red:before {
  background-color: rgba(241, 182, 217, 0.76);
  box-shadow:
      0 0 20px 20px rgba(241, 182, 217, 0.76),
      0 0 60px 60px rgba(255, 168, 220, 0.6),
      0 0 140px 140px rgba(255, 0, 81, 0.51);
}

.glow-img-blue:before {
  background-color: rgba(182, 197, 241, 0.76);
  box-shadow:
      0 0 20px 20px rgba(182, 197, 241, 0.76),
      0 0 60px 60px rgba(168, 182, 255, 0.6),
      0 0 140px 140px rgba(0, 72, 255, 0.51);
}

.glow-img-purple:before {
  background-color: rgba(216, 182, 241, 0.76);
  box-shadow:
      0 0 20px 20px rgba(216, 182, 241, 0.76),
      0 0 60px 60px rgba(219, 168, 255, 0.6),
      0 0 140px 140px rgba(179, 0, 255, 0.51);
}

.side-image__desk {
  max-height: 50vh;
}

@keyframes glowLoop {
  0% {
    height: 40px;
    width: 40px;
  }
  50% {
    height: 80px;
    width: 80px;
  }
  100% {
    height: 40px;
    width: 40px;
  }
}

</style>