<template>
  <div class="line"
       :class="{'hidden':!isVisible}"
       :style="{'--progress':this.scrollPercentage + '%'}">
    <div class="section__step"
         :class="{'active':(scrollPercentage >= section.offset)}"
         :style="{'top': section.offset + '%'}"
         v-for="section in sections" :key="section.innerHTML"
         @click="goto(section.name)"
    >
      <p class="mb-0">{{ section.name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      scrollProgress: null,
      documentHeight: null,
      scrollPercentage: null,
      isVisible: true,
      sections: []
    }
  },
  created() {
    document.addEventListener("scroll", () => {
      this.getScrollData()
    })
  },
  mounted() {
    this.getScrollData()
    this.sections = [...document.getElementsByClassName("section__delimiter")].map(section => ({
      offset: (section.offsetTop / this.documentHeight * 100) + 2,
      name: section.innerHTML.split("<")[0].trim()
    }))
  },
  methods: {
    getScrollData() {
      this.scrollProgress = window.scrollY + window.innerHeight
      this.documentHeight = document.body.scrollHeight
      this.scrollPercentage = parseInt(this.scrollProgress * 100 / this.documentHeight)
      if (window.scrollY >= (window.innerHeight - window.innerHeight * 7.5 / 100) / 2) {
        this.emitHalf(true)
      } else {
        this.emitHalf(false)
      }
      if (this.scrollPercentage >= 95) {
        this.emitHideSidebar(true)
      } else {
        this.emitHideSidebar(false)
      }
    },
    goto(section) {
      const element = document.getElementById(`anchor-${section}`);
      const top = element.offsetTop - window.innerHeight / 3;
      window.scrollTo(0, top);
    },
    emitHalf(isHalf) {
      this.$emit('half', isHalf);
    },
    emitHideSidebar(isHidden) {
      console.log(`hidden ` + isHidden)
      this.$emit('hideSidebar', isHidden);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/colors";

.line {
  width: 2px;
  height: 100%;
  background: $neutral--real-medium;
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  display: flex;
  z-index: 100;
  justify-content: center;

  &:after {
    transition: all .1s;
    content: "";
    height: var(--progress);
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: $neutral--lighter;
  }
}

.section__step {
  height: 10px;
  width: 10px;
  position: absolute;
  background: $neutral--real-medium;
  border-radius: 50%;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  p {
    font-size: 80%;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    opacity: 0;
    transition: all .3s;
    font-weight: bold;
  }

  &:after {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    outline: 5px solid rgba($neutral--lighter, 0);
    position: absolute;
    z-index: -1;
    transition: all .3s;
  }

  &:hover {
    background: $neutral--lighter;

    p {
      left: 170%;
      opacity: 1;
      color: $soft--green;
    }
  }

  &.active {
    background: $neutral--lighter;

    p {
      left: 170%;
      opacity: 1;
    }

    &:after {
      outline: 5px solid rgba($neutral--lighter, .3);
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}
</style>