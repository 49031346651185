<template>
  <div id="app">
    <div class="d-none d-md-block sidebar__container" :class="{'hide':isSidebarVisible}">
      <sidebar-line v-on:half="handleHalfPage" v-on:hideSidebar="handleHideSidebar"/>
    </div>
    <div class="cookie_policy" :class="{'visible':isPolicyVisible}">
      <div class="container my-5">
        <p @click="handlePolicy" class="text-decoration-underline pointer">close</p>
        <h1>Cookie Policy for PWNX</h1>
        <p>This is the Cookie Policy for PWNX, accessible from https://pwnx.io</p>
        <p><strong>What Are Cookies</strong></p>
        <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that
          are downloaded to your computer, to improve your experience. This page describes what information they gather,
          how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break' certain elements of the sites
          functionality.</p>
        <p><strong>How We Use Cookies</strong></p>
        <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry
          standard options for disabling cookies without completely disabling the functionality and features they add to
          this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in
          case they are used to provide a service that you use.</p>
        <p><strong>Disabling Cookies</strong></p>
        <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for
          how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites
          that you visit. Disabling cookies will usually result in also disabling certain functionality and features of
          the this site. Therefore it is recommended that you do not disable cookies. This Cookies Policy was created
          with the help of the <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">Cookies Policy
            Generator from CookiePolicyGenerator.com</a>.</p>
        <p><strong>The Cookies We Set</strong></p>
        <ul>
          <li>
            <p>Site preferences cookies</p>
            <p>In order to provide you with a great experience on this site we provide the functionality to set your
              preferences for how this site runs when you use it. In order to remember your preferences we need to set
              cookies so that this information can be called whenever you interact with a page is affected by your
              preferences.</p>
          </li>
        </ul>
        <p><strong>Third Party Cookies</strong></p>
        <p>In some special cases we also use cookies provided by trusted third parties. The following section details
          which third party cookies you might encounter through this site.</p>
        <ul>
          <li>
            <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the
              web for helping us to understand how you use the site and ways that we can improve your experience. These
              cookies may track things such as how long you spend on the site and the pages that you visit so we can
              continue to produce engaging content.</p>
            <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
          </li>
        </ul>
        <p><strong>More Information</strong></p>
        <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you
          aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with
          one of the features you use on our site.</p>
        <p>For more general information on cookies, please read <a
            href="https://www.generateprivacypolicy.com/#cookies">"Cookies" article from the Privacy Policy
          Generator</a>.</p>
        <p>However if you are still looking for more information then you can contact us through one of our preferred
          contact methods:</p>
        <ul>
          <li>Email: info@pwnx.io</li>
        </ul>
      </div>
    </div>
    <div class="header__container">
      <p-header></p-header>
    </div>
    <div class="loading d-flex align-items-center justify-content-center visible">
      <div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
    </div>
    <div class="container landing position-relative vh-100">
      <div class="row vh-100 d-flex align-items-center justify-content-center">
        <div class="col-md-2 col-8 logo__container" :class="{'sticky':halfPage}">
          <img src="/assets/images/logo_w.png" alt="">
        </div>
      </div>
      <div class="container-fluid landing_footer" :class="{'opacity-0':scrollTop >= 10}">
        <div class="row d-flex justify-content-center">
            <div class='icon-scroll'></div>
        </div>
        <div class="row">
          <p class="text-center cookie-policy__text mt-5" :class="{'opacity-0':scrollTop >= 10}" v-if="showPolicy">By using our website, you agree to our <b @click="handlePolicy" class="pointer text-decoration-underline">Cookies Policy.</b></p>
        </div>
      </div>
    </div>
    <p-section>
      <template v-slot:banner>
        <img src="/assets/images/cover_pwnx.jpg" class="w-100 px-0" alt="">
      </template>
      <template v-slot:content>Be curious, think out of the box, train your skills, everywhere, whenever you want and PWN like an eXpert
      </template>
    </p-section>
    <p-section title="Challenges" color="green" class="mt-5" center>
      <template v-slot:content>
        <div class="col" data-aos="fade-up">
          <p class="text-center">
            Train yourself while having fun on real vulnerable targets.
            This approach helps training practical red-teaming / pentesting skills on controlled and ready-to-hack
            environments, developed using publicly available products, or real-life engagements case scenarios.
          </p>
        </div>
      </template>
    </p-section>
    <div class="container challenge__list">
      <div class="row d-flex justify-content-center">
        <p-category>
          <template v-slot:title>
            web <i class="fas fa-spider"></i>
          </template>
          <template v-slot:description>
            Exploit dozens of vulnerable web applications in different ways: Injections, misconfigurations, exposed pages or default installations.
          </template>
        </p-category>
        <p-category>
          <template v-slot:title>
            pwn <i class="fas fa-microchip"></i>
          </template>
          <template v-slot:description>
            Buffer overflows, Heap overflows, Use-After-Free. It's just a matter of user input.
          </template>
        </p-category>
        <p-category>
          <template v-slot:title>
            reversing <i class="fas fa-history"></i>
          </template>
          <template v-slot:description>
            Train yourself by reversing intricated and vulnerable applications.
          </template>
        </p-category>
        <p-category>
          <template v-slot:title>
            misc <i class="fas fa-blender"></i>
          </template>
          <template v-slot:description>
            Misc challenges are a mesh of everything with the addition of: Steganography, Metadata exfiltration, Conversion, Weak encryption algorithms and more.
          </template>
        </p-category>
      </div>
    </div>

    <p-section side-image="/assets/images/screen_dashboard.png" right>
      <template v-slot:title>
        <h1 class="text-center text-md-start">Competitive</h1>
      </template>
      <template v-slot:content>
        <p>
          Challenge other players in a competitive way, climb the leaderboard and win special prizes!
        </p>
      </template>
    </p-section>
    <p-section side-image="/assets/images/screen_labs.png" right>
      <template v-slot:title>
        <h1 class="text-center text-md-start">Score system</h1>
      </template>
      <template v-slot:content>
        <p>
          The PWNX score system rewards submission speed. The fastest you are to submit, the higher score you will get.
        </p>
      </template>
    </p-section>
    <div class="divider my-5"></div>
    <p-section title="Submissions" color="green" class="mt-5" center>
      <template v-slot:content>
        <p>We are looking for talented security peeps! Do you have an idea of a vulnerable lab? Do you know someone who can help to contribute? Feel free to contact us.
        Our lab creation process is structured like following:</p>
      </template>
    </p-section>
    <p-section>
      <template v-slot:content>
        <div class="row">
          <div class="col-12 my-2 col-md-4">
            <h1>1. Research</h1>
            <p class="text-justify">
              The research process involves scraping the internet for old or new vulnerabilities in a "creative" way. This includes using well-known vulnerabilities that could be exploitable in different ways.
            </p></div>
          <div class="col-12 my-2 col-md-4">
            <h1>2. Develop</h1>
            <p class="text-justify">
              The development process include the creation of the lab in a staging environment, where our team will prepare configuration files, permissions, flags, points and contents. The score of the challenge will be proportionate to the difficulty.
            </p></div>
          <div class="col-12 my-2 col-md-4">
            <h1>3. Release</h1>
            <p class="text-justify">
              When the challenge is ready, it will be published directly on the website and shared on social networks, e-mail, IMs etc.
            </p></div>
        </div>
      </template>
    </p-section>
    <div class="divider my-5"></div>
    <p-section title="Events"
               color="green"
               class="mt-5"
               center
               coming-soon>
      <template v-slot:content>
        <div class="col" data-aos="fade-up">
          <p class="text-center">Multi-layer network scenarios are provided through a scalable infrastructure and
            private network
            environments.</p>
        </div>
      </template>
    </p-section>
    <p-section title="Red" coming-soon color="red" icon="crosshairs"
               side-image="/assets/images/redGem.png">
      <template v-slot:content>
        <p>
          Multi player competitive events based on attack scenarios made out of challenges collections. Get first blood
          before your opponents and climb the leaderboard.
        </p>
<!--        <p-button class="red">test</p-button>-->
      </template>
    </p-section>
    <p-section title="Blue" coming-soon color="blue" class="blue" icon="shield"
               side-image="/assets/images/blueGem.png" right>
      <template v-slot:content>
        <p>
          Patch and defend your environment from external threats.
        </p>
<!--        <p-button class="blue">test</p-button>-->
      </template>
    </p-section>
    <p-section title="Purple" coming-soon color="purple" class="purple" icon="chess-rook"
               side-image="/assets/images/purpleGem.png">
      <template v-slot:content>
        <p>
          Attack and defend your infrastructure at the same time. This highly competitive game-mode simulates a
          cyberwar between multiple teams.
        </p>
<!--        <p-button class="purple">test</p-button>-->
      </template>
    </p-section>
<!--    <div class="divider my-5"></div>-->
<!--    <p-section title="Contact us" color="green">-->
<!--      <template v-slot:content>-->
<!--        <div class="col col-lg-6">-->
<!--          <form id="contact-form" class="w-100">-->
<!--            <label class="mb-2" for="fname">First name:</label><br>-->
<!--            <input class="w-100 py-2 mb-4" type="text" id="fname" name="fname">-->
<!--            <label class="mb-2" for="lname">Last name:</label><br>-->
<!--            <input class="w-100 py-2 mb-4" type="text" id="lname" name="lname">-->
<!--            <label class="mb-2" for="email">Email:</label><br>-->
<!--            <input class="w-100 py-2 mb-4" type="text" id="email" name="email">-->
<!--            <label class="mb-2" for="email">Message:</label><br>-->
<!--            <textarea name="message" rows="5" style="resize:none" class="w-100 py-2"-->
<!--                      form="contact-form">Enter text here...</textarea>-->
<!--          </form>-->
<!--        </div>-->
<!--      </template>-->
<!--    </p-section>-->
    <div class="c container-fluid">
      <div class="row justify-content-around py-3">
        <div class="col mx-4">
          <p><b>Contacts</b></p>
          <div class="divider my-2"></div>
          <p class="mt-2 mb-0">PWNX S.R.L.</p>
          <p class="mb-0">Via di Valle Lupara, 10 00148 Roma</p>
          <p class="mt-2 mb-0"><b>mail: </b><a href="mailto:info@pwnx.io">info@pwnx.io</a></p>
          <p class="mb-0"><b>tel: </b><a href="tel:+39 06 8773 8747">+39 06 8773 8747</a></p>
          <p class="mt-2 mb-0 text-decoration-underline pointer" @click="handlePolicy">cookie policy</p>
        </div>
<!--        <div class="col-1 d-flex align-items-center justify-content-center">-->
<!--          <img class="w-100" src="/assets/images/logo_w.png" alt="">-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import PSection from "./components/PSection";
// import PButton from "./components/PButton";
import Sidebar from "./components/Sidebar-line";
import Header from "./components/Header";
import Category from "./components/Category";

export default {
  name: 'App',
  data() {
    return {
      halfPage: false,
      isSidebarVisible: false,
      showPolicy: true,
      isPolicyVisible: false,
      acceptedPolicy: false,
      scrollTop: 0
    }
  },
  components: {
    "p-section": PSection,
    // "p-button": PButton,
    "sidebar-line": Sidebar,
    "p-header": Header,
    "p-category": Category
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.scrollY;
    },
    handleHalfPage(isHalf) {
      this.halfPage = isHalf;
    },
    handleHideSidebar(hideSidebar) {
      this.isSidebarVisible = hideSidebar;
    },
    handlePolicy() {
      this.isPolicyVisible = !this.isPolicyVisible;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.$cookie.get("consent")) this.showPolicy = false;
    if (!this.$cookie.get("consent")) this.$cookie.set("consent", true, 1);
  },
  mounted() {
    var imgs = document.images,
        len = imgs.length,
        counter = 0;

    [].forEach.call(imgs, function (img) {
      if (img.complete)
        incrementCounter();
      else
        img.addEventListener('load', incrementCounter, false);
    });

    function incrementCounter() {
      counter++;
      if (counter === len) {
        document.getElementsByClassName("loading")[0].classList.remove("visible")
      }
    }
  },
  unmounted() {
    this.$cookie.set('test', 'Hello world!', 1);
  }
}
</script>

<style lang="scss">
@import "./styles/main";

.hide {
  opacity: 0 !important;
  top: -100%;
  pointer-events: none;
}

.loading {
  height: 100%;
  width: 100%;
  margin: 0;
  top: 0;
  background: $neutral--darker;
  z-index: 20;
  position: fixed;
  top: -100%;
  left: 0;
  transition: all .5s;
  pointer-events: none;

  .sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }

  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2.0s infinite ease-in-out both;
  }

  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
  }

  .sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
  }

  .sk-chase-dot:nth-child(2) {
    animation-delay: -1.0s;
  }

  .sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
  }

  .sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
  }

  .sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
  }

  .sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
  }

  .sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
  }

  .sk-chase-dot:nth-child(2):before {
    animation-delay: -1.0s;
  }

  .sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
  }

  .sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
  }

  .sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
  }

  .sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
  }

  @keyframes sk-chase {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot {
    80%, 100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%, 0% {
      transform: scale(1.0);
    }
  }
}

.visible {
  top: 0 !important;
}

.landing {
  img {
    max-width: 100% !important;
  }

  &__footer {
    bottom: 0;
    position: absolute;
  }
}

input,
textarea {
  transition: all .4s;

  &:active,
  &:focus {
    background: rgba(white, .03);
  }
}

.sidebar__container {
  position: fixed;
  height: 100%;
  width: 3%;
  top: 0;
  left: 20px;
  //background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
}

.header__container {
  height: 5%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.logo__container {
  transition: all .2s;
  img {
    width: 100%;
  }

  &.sticky {
    position: fixed;
    width: 10vw;
    top: 5%;
    transform: translateY(-50%);
  }
}

.footer {
  background: $soft--green;

  * {
    color: $neutral--darker !important;
  }
}

.cookie_policy {
  background: $neutral--darker;
  height: 100%;
  width: 100%;
  top: -100%;
  left: 0;
  margin: 0;
  z-index: 200;
  position: fixed;
  overflow: auto;
  transition: all .4s ease-in-out;
}

.icon-scroll {
  transition: all .3s;
}

.cookie-policy__text {
  opacity: 1;
  transition: all .3s;
  font-size: .8rem;
}

.landing_footer {
  height: 20%;
  opacity: 1;
  width: 100%;
  transition: all .3s;
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

</style>
