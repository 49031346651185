import Vue from 'vue'
import App from './App.vue'

import 'aos/dist/aos.css'
import "bootstrap"

import AOS from 'aos'
import VueKinesis from "vue-kinesis"
import VueCookie from "vue-cookie"

const plugins = [VueKinesis, AOS, VueCookie]
plugins.forEach(plugin => {
    Vue.use(plugin)
})

Vue.config.productionTip = false

new Vue({
    created() {
        AOS.init()
    },
    render: h => h(App),
}).$mount('#app')
