<template>
  <div class="header__container d-flex align-items-center justify-content-end p-5">
    <div v-for="button in buttons"
         :key="button.placeholder">
        <span class="header__button mx-3 py-2 px-4" :class="{'button2':button.type == 2}" @click="goTo(button.url)">
          {{ button.placeholder }}
        </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      buttons: [
      // {
      //   placeholder: "Contact us",
      //   url: "link",
      //   type: 1
      // },
        {
          placeholder: "play on pwnx",
          url: "http://play.pwnx.io",
          type: 2
        }]
    }
  },
  methods: {
    goTo(url) {
      window.location = url
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/colors";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.header__button {
  font-size: 90%;
  border: 2px solid;
  color: $neutral--light;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 80%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all .3s;

  &:hover {
    color: $neutral--darker;
    background: $neutral--light;
    border: none;
  }

  &.button2 {
    border: none;
    color: $neutral--darker;
    transition: all .3s;
    overflow: hidden;
    z-index: 3;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $neutral--light;
      width: 100%;
      height: 100%;
      border-bottom: 0px solid transparent;
      border-top: 0px solid transparent;
      border-left: 0px solid;
      transition: all .2s;
      z-index: -1;
    }

    &:hover {
      color: $neutral--light;
      background: none;
      //border-bottom: 1px solid;

      &:after {
        width: 0%;
        left: 100%;
        background: transparent;
        border-bottom: 15px solid transparent;
        border-top: 15px solid transparent;
        //transform: scaleX(1.1);
        border-left: 20px solid $neutral--light;
      }
    }
  }
}

.header__button--container {

}
</style>