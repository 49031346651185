<template>
  <div class="px-4 py-5 px-md-5 py-md-3 m-1 col-11 col-md category__container d-flex align-items-center justify-content-center">
    <h2 class="position-absolute mb-0">
      <slot name="title">
      </slot>
    </h2>
    <p class="mb-0 text-center">
      <slot name="description">
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "Category"
}
</script>

<style scoped lang="scss">
@import "../styles/colors";

.category__container {
  background: $neutral--dark;

  h2 {
    text-transform: uppercase;
    font-size: 130%;
    font-weight: bold;
    transition: all .3s ease-in-out;
    opacity: 1;
  }

  p {
    transform: rotateX(-90deg);
    transition: all .3s ease-in-out;
    opacity: 0;
  }

  &:hover {
    h2 {
      transform: rotateX(90deg);
      opacity: 0;
    }

    p {
      transform: rotateX(0);
      opacity: 1;
    }
  }

  p {
    font-size: 80%;
  }
}
</style>